import Vue from "vue";

/*TITLE*/
document.title = "Amura Village | Porcelanosa PARTNERS";
/*PROJECT TYPE*/
Vue.prototype.$typeProject = "PARTNERS";

/*HERO*/
Vue.prototype.$title = "Amura Village";
Vue.prototype.$subtitle = "Por que eres diferente, Amura Village, pensando en ti";

/*INTRO*/
Vue.prototype.$promoter = "Amura Village";
Vue.prototype.$introSubtitle = "Por que eres diferente, Amura Village, pensando en ti";

/*GALLERY*/
Vue.prototype.$image_sittingroom = "0-livingroom--20221124091723.jpeg";
Vue.prototype.$image_kitchen = "0-kitchen--20221124091723.jpeg";
Vue.prototype.$image_bath1 = "0-bathroom1--20221124091723.jpeg";
Vue.prototype.$image_bath2 = "0-bathroom2--20221124091723.jpeg";
Vue.prototype.$image_room = "";
Vue.prototype.$image_facade = "";

Vue.prototype.$gallery_images_sittingroom = [
  {
    src: "0-livingroom--20221124091723.jpeg",
  },
  {
    src: "1-livingroom--20221124091723.jpeg",
  },
];

Vue.prototype.$gallery_images_kitchen = [
  {
    src: "0-kitchen--20221124091723.jpeg",
  },
  {
    src: "1-kitchen--20221124091723.jpeg",
  },
  {
    src: "2-kitchen--20221124091723.jpeg",
  },
  {
    src: "3-kitchen--20221124091723.jpeg",
  },
  {
    src: "4-kitchen--20221124091723.jpeg",
  },
  {
    src: "5-kitchen--20221124091723.jpeg",
  },
];

Vue.prototype.$gallery_images_bath1 = [
  {
    src: "0-bathroom1--20221124091723.jpeg",
  },
];

Vue.prototype.$gallery_images_bath2 = [
  {
    src: "0-bathroom2--20221124091723.jpeg",
  },
];

Vue.prototype.$gallery_images_room = [
];

Vue.prototype.$gallery_images_facade = [
];

/*GALLERY GRID*/
Vue.prototype.$gallery_grid_sittingroom = "6";
Vue.prototype.$gallery_grid_kitchen = "6";
Vue.prototype.$gallery_grid_bath1 = "3";
Vue.prototype.$gallery_grid_bath2 = "3";
Vue.prototype.$gallery_grid_room = "12";
Vue.prototype.$gallery_grid_facade = "12";

/*TOUR VIRTUAL*/
Vue.prototype.$360 = "https://360.porcelanosapartners.com/VISTAHERMOSA/";

/*3D FILM*/
Vue.prototype.$3dFilm = "https://www.youtube.com/embed/iCgwZUzbvwo";

/*CATALOG*/
Vue.prototype.$catalog = true;

/*SPACES*/
Vue.prototype.$spaces = "";

/*PROMOTION LOGO*/
Vue.prototype.$promotion_logo = true;

/*PROMOTER LOGO*/
Vue.prototype.$promoter_logo = true;

/*CONTACT*/
Vue.prototype.$promoter_address = [
  {
    icon: "mdi-map-marker",
    link: "https://maps.google.com/?q=calle%20cigueña%203%20alicante",
    text: "Calle Cigueña, 3 Alicante",
  },
];

Vue.prototype.$showroom = [
  {
    icon: "mdi-map-marker",
    link: "https://maps.google.com/?q=calle%20cigueña%203%20alicante",
    text: "Calle Cigueña, 3 Alicante",
  },
];

Vue.prototype.$contact = [
  {
    icon: "mdi-phone",
    link: "telf:667589664",
    text: "667589664",
  },
  {
    icon: "mdi-email",
    link: "mailto:info@amura-village.com",
    text: "info@amura-village.com",
  },
];
